<template>
  <section>
    <!--<link
      rel="stylesheet"
      href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
      integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
      crossorigin="anonymous"
    />-->
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->

    <div class="row">
      <div class="col-md-7">
        <h2 class="titulo-links-escolha">Escolas</h2>
      </div>
      <div class="col-md-5 tbl-cadastro" v-if="situacaoAno">
        <pm-Button
          v-if="acesso.cadastrar"
          type="button"
          @click="registar()"
          label="CADASTRAR NOVA ESCOLA"
          class="p-button-success"
          icon="pi pi-external-link"
          title="CADASTRAR NOVA ESCOLA"
        >
        </pm-Button>
      </div>
    </div>

    <div class="pm-card" v-if="acesso.visualizar">
      <pm-DataTable
        :value="escolaALL"
        dataKey="id"
        :rowHover="true"
        filterDisplay="menu"
        v-model:filters="filters"
        :globalFilterFields="['nome']"
      >
        <template #header>
          <div class="flex justify-content-center align-items-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <pm-InputText
                style="height: 34px !important;"
                v-model="pesquisa"
                @input="pesquisar"
                placeholder="Pesquisar Escola"
              />
            </span>
          </div>
        </template>
        <pm-Column
          field="nome"
          sortable
          header="NOME"
          class="colunn-tbl"
        ></pm-Column>

        <pm-Column
          field="id"
          headerStyle="width: 4rem; text-align: center"
          class="colunn-tbl2"
        >
          <template #body="{ data }">
            <pm-Button
              type="button"
              @click="verTurmas(data.id)"
              icon="pi pi-external-link"
              label="Ver Turmas"
              title="Ver Turmas"
              v-if="situacaoAno && acesso.visualizar"
              class="p-button-sm btn-color p-button-success"
              style="margin-left: 5px"
            >
            </pm-Button>

            <pm-Button
              type="button"
              @click="editarEscola(data.id)"
              icon="pi pi-pencil"
              label="Editar"
              title="Editar"
              v-if="situacaoAno && acesso.editar"
              class="p-button-sm btn-color"
              style="margin-left: 5px"
            ></pm-Button>
            <pm-Button
              v-if="situacaoAno && acesso.deletar"
              title="Apagar"
              type="button"
              @click="deletarEscola(data.id)"
              label="Apagar"
              icon="pi pi-times-circle"
              class="p-button-danger p-button-sm btn-color"
              style="margin-left: 5px"
            ></pm-Button>
          </template>
        </pm-Column>
      </pm-DataTable>
      <div class="p-paginator p-component p-paginator-bottom">
        <div class="p-paginator-pages" style="display: inline-flex">
          <div v-for="n in links" :key="n">
            <button
              class="p-paginator-next p-paginator-element p-link"
              @click="paginas(n)"
              v-if="!n.active"
              style="border: 0px solid #ebebeb; border-radius: 0"
            >
              <p
                class="p-paginator-icon"
                v-html="n.label"
                style="margin-bottom: 0px !important"
              ></p>
            </button>
            <button
              class="p-paginator-page p-paginator-element p-link p-highlight"
              @click="paginas(n)"
              v-if="n.active"
              style="border-radius: 0"
            >
              {{ n.label }}
            </button>
          </div>
        </div>
        <selectPorPag qdt="20" @changePagina="changePagina"></selectPorPag>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Escola } from "@/class/escolas";
import axios from "axios";
import { Verificar } from "@/class/verificar.js";
import { LoginInfo } from "@/class/login";
import selectPorPag from "@/components/selectPorPag.vue";
import pagina from "@/class/pagina";

export default defineComponent({
  components: {
    selectPorPag
  },
  data() {
    const columns = [
      { key: "nome", label: "Escola", sortable: true },
      { key: "logradouro" },
      { key: "bairro", sortable: true },
      { key: "id", label: "Ação" },
    ];
    return {
      funcao: 0,
      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Escolas" }],
      situacaoAno: 1,
      filter: "",
      escolaALL: [],
      columns,
      perPage: 20,
      currentPage: 1,
      links: [],
      turmas: null,
      turma_nome: null,
      acesso: {
        visualizar: 1,
        cadastrar: 1,
        editar: 1,
        deletar: 1,
      },
      pessoaId: 0,
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.escolaALL.length / this.perPage)
        : this.escolaALL.length;
    },
  },
  async beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    this.funcUsuario = parseInt(sessionStorage.getItem("funcaoDoUsuario"));
    await this.logado();
    await this.listarTudo();
    const acesso = await Verificar.AcessoLocal(2);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
  },
  methods: {
    changePagina(pg){
      pagina.routePg = "&pg="+pg
      pagina.pg = pg
      this.listarTudo()
    },
    async logado() {
      const token = sessionStorage.getItem("token");
      const data = await LoginInfo.WhoIam(token);
      this.funcao = data.data.funcao;
      this.pessoaId = data.data.usuario.id;
    },
    verTurmas(id) {
      if (this.funcao === 3 || this.funcao === 4) {
        this.$router.push({
          name: "coordenacao-turma-hab",
          params: { escola_turm_p: id },
        });
      } else if (this.funcao === 1 || this.funcao === 2 || this.funcao === 7) {
        this.$router.push({ name: "turmas", params: { escola_turm_p: id } });
      }
    },
    async deletarEscola(id) {
      if (
        window.confirm("Você realmente deseja apagar a escola selecionada?")
      ) {
        try {
          const dt = await Escola.remover(id);
          this.escolaALL = [];
          this.$vaToast.init({
            message: "Removido com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            color: "success",
            duration: 2500,
            fullWidth: false,
          });
          this.listarTudo();
        } catch (e) {
          const a = e.response.data.erro;

          setTimeout(() => {
            this.$vaToast.init({
              message: a,
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
          }, 500);
        }
      }
    },
    async paginas(n) {
      if (n.url != null) {
        if (!n.active) {
          const routePg = pagina.routePg;
          const res = await axios.get(n.url+routePg);
          this.escolaALL = [];
          //this.escolaALL = res.data.data;
          this.links = res.data.links;
          for (const el of res.data.data) {
            const novo = {
              id_: el.id,
              id: el.id,
              logradouro: el.logradouro,
              bairro: el.bairro,
              cep: el.cep,
              cidade: el.cidade,
              cnpj: el.cnpj,
              email: el.email,
              estado: el.estado,
              nome: el.nome,
              numero: el.numero,
              items: [
                {
                  label: "Editar Escola",
                  icon: "pi pi-pencil",
                  command: () => {
                    this.editarEscola(el.id);
                  },
                },
                {
                  label: "Informações da Escola",
                  icon: "pi pi-search-plus",
                  command: () => {
                    this.editarInfo(el.id);
                  },
                },
                {
                  label: "Cadastrar Turma",
                  icon: "pi pi-pencil",
                  command: () => {
                    this.cadastrarTurma();
                  },
                },
                {
                  label: "Cadastrar Aluno",
                  icon: "pi pi-pencil",
                  command: () => {
                    this.cadastrarAluno();
                  },
                },

                {
                  label: "Deletar Escola",
                  icon: "pi pi-times-circle",
                  command: () => {
                    this.deletarEscola(el.id);
                  },
                },
              ],
            };
            this.escolaALL.push(novo);
          }
          this.escolaALL.reverse();
        }
      }
    },
    async pesquisar() {
      try {
        if (this.pesquisa != null && this.pesquisa != "") {
          const data = await Escola.pesqEscolaCompleto(this.pesquisa);
          this.escolaALL = [];
          this.links = [];

          for (const el of data.data) {
            const novo = {
              id_: el.id,
              id: el.id,
              logradouro: el.logradouro,
              bairro: el.bairro,
              cep: el.cep,
              cidade: el.cidade,
              cnpj: el.cnpj,
              email: el.email,
              estado: el.estado,
              nome: el.nome,
              numero: el.numero,
              items: [
                {
                  label: "Editar Escola",
                  icon: "pi pi-pencil",
                  command: () => {
                    this.editarEscola(el.id);
                  },
                },
                {
                  label: "Informações da Escola",
                  icon: "pi pi-search-plus",
                  command: () => {
                    this.editarInfo(el.id);
                  },
                },
                {
                  label: "Cadastrar Turma",
                  icon: "pi pi-pencil",
                  command: () => {
                    this.cadastrarTurma();
                  },
                },
                {
                  label: "Cadastrar Aluno",
                  icon: "pi pi-pencil",
                  command: () => {
                    this.cadastrarAluno();
                  },
                },

                {
                  label: "Deletar Escola",
                  icon: "pi pi-times-circle",
                  command: () => {
                    this.deletarEscola(el.id);
                  },
                },
              ],
            };
            this.escolaALL.push(novo);
          }
          this.escolaALL.reverse();
        } else {
          this.listarTudo();
        }
      } catch (e) {
        // console.log(e)
      }
    },
    editarEscola(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({ name: "escolasEditar", params: { idEscola: id } });
      } else {
        this.$router.push({
          name: "escola-coordenacao-editar",
          params: { idEscola: id },
        });
      }
    },
    editarInfo(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({ name: "escolasInfo", params: { idEscola: id } });
      } else {
        this.$router.push({
          name: "escola-coordenacao-informacao",
          params: { idEscola: id },
        });
      }
    },
    irEscola(id) {
      this.$router.push({ name: "escola", params: { id: id } });
    },
    async buscarTurmas(id, nome) {
      this.turma_nome = null;
      this.turmas = null;
      this.turma_nome = nome;
      const anoSelect = sessionStorage.getItem("anoSelecionado");
      const data = await Escola.obtemInfoEscola(id, anoSelect);
      this.turmas = data.data;

      if (this.turmas.length == 0) {
        this.turmas = null;
      }
    },
    cadastrarTurma() {
      this.$router.push({ name: "turmasCadastrar" });
    },
    cadastrarAluno() {
      this.$router.push({ name: "aluno-basic-info-cadastrar" });
    },
    registar() {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({ name: "escolasCadastro" });
      } else {
        this.$router.push({ name: "escola-coordenacao-cadastrar" });
      }
    },
    async listarTudo() {
      try {
        this.escolaALL = []
        const data = await Escola.obtemTodosPG(this.currentPage);
        this.links = data.data.links;

        for (const el of data.data.data) {
          const novo = {
            id_: el.id,
            id: el.id,
            logradouro: el.logradouro,
            bairro: el.bairro,
            cep: el.cep,
            cidade: el.cidade,
            cnpj: el.cnpj,
            email: el.email,
            estado: el.estado,
            nome: el.nome,
            numero: el.numero,
            items: [
              {
                label: "Editar Escola",
                icon: "pi pi-pencil",
                command: () => {
                  this.editarEscola(el.id);
                },
              },
              {
                label: "Informações da Escola",
                icon: "pi pi-search-plus",
                command: () => {
                  this.editarInfo(el.id);
                },
              },
              {
                label: "Cadastrar Turma",
                icon: "pi pi-pencil",
                command: () => {
                  this.cadastrarTurma();
                },
              },
              {
                label: "Cadastrar Aluno",
                icon: "pi pi-pencil",
                command: () => {
                  this.cadastrarAluno();
                },
              },

              {
                label: "Deletar Escola",
                icon: "pi pi-times-circle",
                command: () => {
                  this.deletarEscola(el.id);
                },
              },
            ],
          };
          this.escolaALL.push(novo);
        }
        this.escolaALL.reverse();
      } catch (e) {}
    },
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
<style>
.azul-luz {
  background: #336aeb !important;
  box-shadow: 0 2px 22px #9db2e3e6;
}
.red-luz {
  background: #eb4433 !important;
  box-shadow: 0 2px 22px #e39d9de6;
}
</style>
